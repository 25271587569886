import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ServiceReminderService } from 'src/app/_services/service-reminder.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CreateServiceReminderComponent } from './create-service-reminder/create-service-reminder.component';
import { ServiceDetailsComponent } from './service-details/service-details.component';
import { UpdateServiceReminderComponent } from './update-service-reminder/update-service-reminder.component';
import { MenuItem } from 'primeng/api';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IssuesService } from 'src/app/_services/issues.service';
import { ActiveIssueReportDialogComponent } from '../active-issues/active-issue-report-dialog/active-issue-report-dialog.component';
import { Table } from 'primeng/table';
@Component({
  selector: 'app-service-reminder',
  templateUrl: './service-reminder.component.html',
  styleUrls: ['./service-reminder.component.scss'],
})
export class ServiceReminderComponent implements OnInit {
  servcieReminders: any = [];
  ref: any;
  selectedCustomer: any;
  reminder: any;
  reminders$ = this.serviceReminderService.serviceReminders$;
  loadingServiceReminder$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  position: string = 'center';
  visible: boolean = false;
  @ViewChild('signedOffIssuesTable') signedOffIssuesTable: Table | undefined;

  columns = [
    { field: 'vehicle_reference', header: 'Vehicle Reference' },
    { field: 'km_reading', header: 'Service Delta' },
  ];

  items: MenuItem[] = [
    {
      label: 'Edit',
      icon: 'pi pi-pencil',
      command: () => this.updateReminder(this.selectedCustomer),
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash',
      command: () => this.deleteReminder(this.selectedCustomer),
    },
  ];

  constructor(
    private serviceReminderService: ServiceReminderService,
    private dialogService: DialogService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public issuesService: IssuesService
  ) {}

  ngOnInit(): void {
    this.serviceReminderService.getReminders().subscribe((data) => {
      this.serviceReminderService.updateReminders(data);
    });
    setTimeout(() => {
      this.loadingServiceReminder$.next(false);
    }, 1000);
  }

  filterGlobal(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.signedOffIssuesTable?.filterGlobal(inputElement.value, 'contains');
  }

  storeRowDetail(rowData: any) {
    this.selectedCustomer = rowData;
  }

  showCreateServiceReminder() {
    this.ref = this.dialogService.open(CreateServiceReminderComponent, {
      header: 'Create Service Reminder',
      width: '70%',
    });
  }

  // showServiceDetails(data: any) {
  //   this.ref = this.dialogService.open(ServiceDetailsComponent, {
  //     header: 'Service Details',
  //     width: '85%',
  //     data: data,
  //   });
  // }

  updateReminder(reminder: any) {
    this.ref = this.dialogService.open(UpdateServiceReminderComponent, {
      header: 'Update Service Reminder',
      width: '70%',
      data: reminder,
    });
  }

  deleteReminder(reminder: any) {
    this.confirmationService.confirm({
      key: 'service_reminder',
      message: 'Are you sure that you want to delete this reminder?',
      accept: () => {
        this.serviceReminderService
          .deleteReminder(reminder.id)
          .subscribe(() => {
            this.serviceReminderService.getReminders().subscribe((data) => {
              this.serviceReminderService.updateReminders(data);
            });
          });
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Service reminder deleted',
          key: 'service_reminder',
        });
      },
      reject: () => {
        console.log('Delete cancelled');
        this.messageService.add({
          severity: 'info',
          summary: 'Info',
          detail: 'Delete cancelled',
          key: 'service_reminder',
        });
      },
    });
  }

  generate_excel_report() {
    this.issuesService.getIssueReport();
  }

  generate_csv_report() {
    this.issuesService.getCsvReport();
  }

  showReportDialog() {
    this.ref = this.dialogService.open(ActiveIssueReportDialogComponent, {
      header: 'Reporting',
      width: '60%',
      height: '35%',
      closeOnEscape: true,
    });
  }

  showDialog(position: string) {
    this.position = position;
    this.visible = true;
  }
}
