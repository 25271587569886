<p-confirmDialog></p-confirmDialog>
<p-toast key="vehicles"></p-toast>
<div class="flex flex-column flex-auto">
  <div class="grid mb-2">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-truck text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Vehicles</span>
        </div>
      </div>
    </div>
  </div>
  <ng-template #loadedContent>
    <!-- vehicle actions /table-->
    <div
      class="block overflow-hidden surface-overlay w-full shadow-2 p-2"
      style="max-width: 100vw"
    >
        <p-table
          #dt
          [value]="(vehicles$ | async) || []"
          [tableStyle]="{ 'min-width': '70rem' }"
          [rowHover]="true"
          [responsiveLayout]="'scroll'"
          [scrollable]="true"
          scrollHeight="75vh"
          styleClass="p-datatable-sm lg:p-datatable-lg"
          [globalFilterFields]="[
            'vehicle_reference',
            'vehicle_make',
            'vehicle_model',
            'vehicle_year',
            'chassis_no',
            'model',
            'engine_no'
          ]"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($event)"
                  placeholder="Search keyword"
                />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th
                class="bg-bluegray-700 border-right-3"
                *ngFor="let col of columns"
                [pSortableColumn]="col.field"
              >
                <span class="font-semibold text-white">{{ col.header }}</span>
              </th>
              <th class="bg-bluegray-700 border-right-3"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-vehicle>
            <tr style="cursor: pointer">
              <td *ngFor="let col of columns">
                <div class="flex align-items-center">
                  <div>
                    <p
                      class="mt- mb-2 font-medium text-sm md:text-md text-color-primary"
                    >
                      {{ vehicle[col.field] }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <button
                  type="button"
                  pButton
                  icon="pi pi-ellipsis-v"
                  class="p-button-text p-button-secondary text-gray-800"
                  (click)="
                    menu.toggle($event);
                    storeRowDetail(vehicle);
                    $event.stopPropagation()
                  "
                ></button>
                <p-menu
                  #menu
                  [model]="items"
                  [popup]="true"
                  appendTo="body"
                ></p-menu>
                <p-menu #menu appendTo="body" [popup]="true"></p-menu>
              </td>
            </tr>
          </ng-template>
        </p-table>
    </div>
    <div class="floating-button">
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-plus"
        class="p-button-rounded p-button-info p-button-sm bg-bluegray-700 hover:bg-bluegray-500"
        (click)="showAdd($event)"
      >
        <span class="p-2">Add Vehicle</span>
      </button>
    </div>
  </ng-template>
</div>

<div *ngIf="this.Vehicles$ | async; else loadedContent">
  <div class="surface-card shadow-2 flex p-3 flex-column">
    <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
    </div>
  </div>
</div>
