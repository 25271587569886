import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IssuesService } from 'src/app/_services/issues.service';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { HotkeysService } from 'src/app/_services/hotkeys.service';
import { Hotkey } from 'src/app/_models/hotkey';

@Component({
  selector: 'app-active-issue-form',
  templateUrl: './active-issue-form.component.html',
  styleUrls: ['./active-issue-form.component.scss'],
})
export class ActiveIssueFormComponent implements OnInit {
  activeIssueForm: FormGroup; // Form group for the active issue form
  vehicleList: any[] = []; // List of vehicles
  selectedVehicle: any; // Currently selected vehicle
  signedOff: boolean = false; // Flag to check if signed off
  signOfTypes: any[] = []; // List of sign-off types
  loading: boolean = false; // Flag to check if loading
  suggestions: any[] = [
    { label: 'Issue 1', value: 'Issue 1', new: false },
    { label: 'Issue 2', value: 'Issue 2', new: false },
    { label: 'Issue 3', value: 'Issue 3', new: false },
  ];

  suggestedIssues: any[] = []; // List of suggested issues
  selectedIssues: any[] = []; // List of selected issues
  editFormData: any; // Data for editing an existing issue
  selectedSignOffType: any; // Currently selected sign-off type
  selectedRemarks: any; // Remarks for the issue
  isInEditMode: boolean = false; // Flag to check if in edit mode
  getVehicleReference: any; // Placeholder for vehicle reference
  editFormGroups: FormGroup[] = []; // Form groups for editing multiple issues
  suggestedDescriptions: any[] = []; // List of suggested descriptions

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    private issuesService: IssuesService,
    private vehiclesService: VehiclesService,
    private router: Router,
    public location: Location,
    public messageService: MessageService,
    public hotkeysService: HotkeysService
  ) {
    // Initialize the form with default values and validators
    this.activeIssueForm = this.fb.group({
      vehicle_reference: ['', Validators.required],
      description: [[], Validators.required],
      remarks: ['', Validators.required],
      label: '',
      value: '',
    });
  }

  // commentEnabled: boolean;

  vehiclesList: any; // List of vehicles
  selectMapObject = {
    key: 'id',
    descriptorFields: 'vehicle_ID',
  }; // Object to map select options

  ngOnInit() {
    this.isInEditMode = false; // Initially not in edit mode
    console.log(this.isInEditMode);

    this.getVehiclesOptionsList(); // Fetch the list of vehicles

    // Define sign-off types
    this.signOfTypes = [
      { signoff_type: 'General' },
      { signoff_type: 'Technical' },
      { signoff_type: 'Fines' },
    ];

    this.updateFormData(); // Update form data if in edit mode
    console.log('edit form group', this.editFormGroups);
  }

  // Fetches the list of vehicles from the service
  getVehiclesOptionsList() {
    return this.vehiclesService.getVehicles().subscribe((vehicles: any[]) => {
      this.vehicleList = vehicles.map((vehicle) => ({
        vehicle_reference: vehicle.vehicle_reference,
      }));
    });
  }
  // Filter descriptions based on the query input
  filterDescriptions(event: any) {
    const query = event.query;
    // Fetch hotkeys from the service based on the query
    this.hotkeysService.filterHotkeys(query).subscribe((hotkeys: Hotkey[]) => {
      this.suggestedDescriptions = hotkeys.map((hotkey) => ({
        label: hotkey.tag_description || hotkey.hotkey_main,
        value: hotkey.tag_description || hotkey.hotkey_main,
      }));
      // Add an option to create a new item if no matches found
      if (this.suggestedDescriptions.length === 0) {
        this.suggestedDescriptions.push({
          label: `Add "${query}"`,
          value: query,
          new: true,
        });
      }
    });
  }

  onDescriptionSelect(event: any) {
    let selectedIssue = event;
    selectedIssue.label = selectedIssue.value;
    this.selectedIssues.push(selectedIssue);
  }

  // Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data.formdata;
    console.log('editFormData', this.editFormData);
    if (this.editFormData instanceof Array) {
      if (this.editFormData.length > 0) {
        this.editFormData.forEach((desc: any) => {
          const formGroup = this.fb.group({
            id: desc.id,
            vehicle_reference: [desc.vehicle_reference],
            //idk why but it works now
            description: [
              Array.isArray(desc.description)
                ? desc.description
                : [
                    {
                      label: desc.description,
                      value: desc.description,
                    },
                  ],
              Validators.required,
            ],
            //description: [[], Validators.required],
            remarks: [desc.remarks, Validators.required],
          });
          this.editFormGroups.push(formGroup);
        });
        this.isInEditMode = true;
      }
    } else if (this.editFormData) {
      const transformedData = {
        ...this.editFormData,
        description: Array.isArray(this.editFormData.description)
          ? this.editFormData.description
          : [this.editFormData.description],
      };
      this.activeIssueForm.patchValue(transformedData);
      this.isInEditMode = true;
      this.activeIssueForm.patchValue(this.editFormData!);
    }
  }

  // Implement trackBy function for ngFor trackBy directive to optimize rendering
  trackByFn(index: number, item: any): number {
    return index;
  }

  // Maps data to select options format
  mapDataToOptions(dataList: any): any {
    let newData = dataList.map((data: any) => {
      return {
        label: data[this.selectMapObject.descriptorFields],
        value: data[this.selectMapObject.descriptorFields],
      };
    });

    newData = newData.sort((a: any, b: any) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
    console.log('new data', newData);
    return newData;
  }

  // Handles sign-off checkbox change event
  onSignOff(event$: any) {
    this.signedOff = event$.checked;
  }

  // Handles issue selection from suggestions
  onIssueSelect($event: any) {
    let selectedIssue = $event;

    if (selectedIssue.new) {
      // Create a new issue if it's a new selection
      selectedIssue = {
        label: selectedIssue.value,
        value: selectedIssue.value,
        new: true,
      };
      this.selectedIssues.push(selectedIssue);
    }
  }

  // Filters issues based on the query input
  filterIssues($event: any) {
    let filtered: any[] = [];
    let query = $event.query;

    this.suggestions.filter((issue) => {
      if (
        String(issue.label)
          .toLocaleLowerCase()
          .includes(query.toLocaleLowerCase())
      ) {
        filtered.push(issue);
      }
    });

    this.suggestedIssues = filtered;

    // Add the option to create a new item if no matches found
    if (this.suggestedIssues.length === 0) {
      this.suggestedIssues.push({
        label: `Add "${query}"`,
        value: query,
        new: true,
      });
    }
  }

  // Handles form submission for creating or updating an issue
  onActiveIssueFormSubmit() {
    this.loading = true;

    for (let i = 0; i < this.editFormGroups.length; i++) {
      if (this.editFormGroups[i].invalid) {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill in all the required fields',
          key: 'active_issues',
        });
        return;
      }
    }

    if (!this.editFormData) {
      if (this.activeIssueForm.invalid) {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please fill in all the required fields',
          key: 'active_issues',
        });
        return;
      }

      const formValue = { ...this.activeIssueForm.value };
      formValue.description =
        formValue.description.label || formValue.description;
      //formValue.description = formValue.description.map((desc: any) => desc.label || desc);
      try {
        const formattedIssue =
          this.issuesService.formatSubmittedIssue(formValue); // Format the issue
        this.issuesService.addActiveIssue(formattedIssue); // Pass the formatted issue
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Issue has been added',
          key: 'active_issues',
        });
        this.ref.close();
        this.loading = false;
      } catch (error) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Issue has failed to add',
          key: 'active_issues',
        });
      }
    } else if (this.editFormData) {
      if (this.editFormGroups.some((formGroup) => formGroup.invalid)) {
        this.editFormGroups.forEach((formGroup) =>
          formGroup.markAllAsTouched()
        );
        return;
      }

      if (this.ref) {
        this.isInEditMode = false;
        if (this.editFormGroups.length > 0) {
          this.editFormGroups.forEach((formGroup, index) => {
            const formGroupValue = { ...formGroup.value };
            //|| formGroupValue.description
            formGroupValue.description = formGroupValue.description[0].label;
            console.log(
              `Submitting form group ${index}:`,
              formGroupValue.description
            );
            this.issuesService.updateActiveIssue(
              formGroupValue.id,
              formGroupValue
            );
          });
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Issue has successfully updated',
            key: 'active_issues',
          });
          this.ref.close();
        } else {
          const formValue = { ...this.activeIssueForm.value };
          formValue.description =
            formValue.description.label || formValue.description;
          try {
            const formattedIssue =
              this.issuesService.formatSubmittedIssue(formValue); // Format the issue
            this.issuesService.updateActiveIssue(
              this.editFormData.id,
              formattedIssue
            ); // Update the formatted issue
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Issue has successfully updated',
              key: 'active_issues',
            });
            this.ref.close();
          } catch (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: 'Issue has failed to update',
              key: 'active_issues'
            });
          }
        }
      }
    }
  }
}
