import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Subscriber } from 'rxjs';
import { IssuesService } from 'src/app/_services/issues.service';
import { SignedOffService } from 'src/app/_services/signed-off.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-signoff-issue',
  templateUrl: './signoff-issue.component.html',
  styleUrls: ['./signoff-issue.component.scss']
})
export class SignoffIssueComponent implements OnInit {
  signOfTypes: any[] = [];
  issueData: any[] = [];
  signedOffData: any;
  vehicleData: any;
  updateData: any;
  @Output() signOffOutput = new EventEmitter<any>();
  activeIssue: any;
  editFormGroups: FormGroup[] = [];
  selectedSignOffType: any;
  date: string = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  fine_amount: any = "0";
  loading: boolean = false;

  @ViewChild(FormGroupDirective)
  private formDir !: FormGroupDirective;

  loadingSignOff$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    private issuesService: IssuesService,
    public ref: DynamicDialogRef,
    public signedOffService: SignedOffService,
    private router: Router,
    private location: Location,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.issueData = this.config.data.descriptionData;
    this.vehicleData = this.config.data.data;
    
    if (Array.isArray(this.issueData) && this.vehicleData.length > 0) {
      this.vehicleData.forEach((desc: any) => {
        const formGroup = this.fb.group({
          id: desc.id,
          signoff_type: desc.signoff_type,
          fine_amount: [desc.fine_amount, Validators.required],
          description: desc.description,
          remarks: desc.remarks,
          vehicle_reference: desc.vehicle_reference,
          date_signed_off: desc.date_signed_off,
        });
        this.editFormGroups.push(formGroup);
      });
    }

    this.signOfTypes = [
      { signoff_type: 'General' },
      { signoff_type: 'Technical' },
      { signoff_type: 'Fines' },
    ];

    // Simulate loading completion
    setTimeout(() => {
      this.loadingSignOff$.next(false);
    }, 1000); // Adjust the simulated loading time as needed
  }

  showFineAmount: boolean[] = new Array(this.issueData.length).fill(false);

  ngOnDestroy(): void {
    this.formDir.resetForm();
  }

  toggleFineAmount(event: any, index: number): void {
    const selectedSignoffType = event.value;
    this.showFineAmount[index] = selectedSignoffType === 'Fines';
  }

  onSignOffIssueFormSubmit(): void {
    this.loading = true;
    if (this.editFormGroups.length > 0) {
      console.log('editFormGroups', this.editFormGroups);
      
      this.editFormGroups.forEach((formGroup) => {
        formGroup.value.signed_off = true;
        formGroup.value.fine_amount = this.fine_amount;
        formGroup.value.date_signed_off = this.date;
        formGroup.value.signoff_type = this.selectedSignOffType;
        this.updateDescription(formGroup.value.id, formGroup.value);
      });
      for (let i = 0; i < this.editFormGroups.length; i++) {
        console.log('signed off');
      }
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Issue has been signed off', key: 'active_issues' });
      this.ref.close();
      this.loading = false;
    } else {
      const signOffFormValue = this.signedOffService.signOffForm.value;
      signOffFormValue.signed_off = true;
      signOffFormValue.vehicle_reference = this.vehicleData.vehicle_reference;
      signOffFormValue.description = this.vehicleData.description;
      signOffFormValue.remarks = this.vehicleData.remarks;
      signOffFormValue.fine_amount = this.fine_amount;
      signOffFormValue.date_signed_off = this.date;
      this.signedOffService.sendData(signOffFormValue);
      this.updateDescription(this.vehicleData.id, signOffFormValue);
      this.ref.close();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Issue has been signed off', key: 'active_issues' });
    }
  }
  updateDescription(id: number, data: any): void {
    this.issuesService.updateActiveIssue(id, data)
  }
}
//   updateDescription(id: number, data: any): void {
//     this.issuesService.updateActiveIssue(id, data).subscribe(response => {
//       console.log('Update successful', response);
//     }, error => {
//       console.error('Update failed', error);
//     });
//   }
// }
