import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ServiceReminderService } from 'src/app/_services/service-reminder.service';
import { VehiclesService } from 'src/app/_services/vehicles.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-service-reminder',
  templateUrl: './create-service-reminder.component.html',
  styleUrls: ['./create-service-reminder.component.scss'],
})
export class CreateServiceReminderComponent implements OnInit {
  vehicle_id: any;
  vehicle_references: any = [];
  vehicleList: any[] = [];
  loading: boolean = false;
  services$: Subject<any> = new Subject<any>();
  serviceForm = this.fb.group({
    service_delta: [''],
    service_due_date: [''],
    vehicle_reference: ['', Validators.required],
    vehicle_id: [''],
    km_reading: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private serviceReminderService: ServiceReminderService,
    private vehiclesService: VehiclesService,
    public ref: DynamicDialogRef,
    public messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.vehiclesService.getVehicles().subscribe((vehicles: any[]) => {
      this.vehicleList = vehicles.map((vehicle) => ({
        vehicle_reference: vehicle.vehicle_reference,
      }));
    });

    this.serviceForm = this.fb.group({
      service_delta: [''],
      service_due_date: [''],
      vehicle_reference: ['', Validators.required],
      vehicle_id: [''],
      km_reading: ['', Validators.required],
    });
  }

  createReminder() {
    this.loading = true;
    console.log(this.serviceForm.invalid);

    if (this.serviceForm.invalid) {
      this.loading = false;
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all the required fields',
      });
      return;
    }

    let vehicle_reference: string =
      this.serviceForm.get('vehicle_reference')!.value!;
    this.vehiclesService
      .getVehicleByReference(vehicle_reference)
      .subscribe((data) => {
        this.vehicle_id = data.body.id;
        this.serviceForm.patchValue({
          vehicle_id: this.vehicle_id,
        });
        console.log(this.serviceForm.value);

        this.serviceReminderService
          .addReminder(this.serviceForm.value)
          .subscribe(
            (data) => {
              this.serviceReminderService.getReminders().subscribe((data) => {
                console.log(data);

                this.serviceReminderService.updateReminders(data);
              });
            },
            (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Service reminder not created',
                key: 'service_reminder',
              });
              this.ref.close();
            },
            () => {
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Service reminder created',
                key: 'service_reminder',
              });

              this.ref.close();
            }
          );
      });
  }
}
