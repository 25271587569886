<div
  *ngIf="this.loadingSignOff$ | async; else loadedContentSignedOff"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>
<ng-template #loadedContentSignedOff>
  <p-toast key="active_issues"></p-toast>
  <div
    class="block overflow-hidden surface-overlay md:w-full pb-6"
    style="max-width: 90vw"
  >
    <p-tabView styleClass="tabview-custom">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="text-bluegray-800 bg">Active Issues</span>
        </ng-template>
        <div class="flex align-items-center justify-content-between">
          <!-- <div class="text-900 font-medium text-xl">Active Issues</div> -->
          <!-- <div>
            <button
              pButton
              pRipple
              icon="pi pi-plus"
              class="p-button-plain p-button-rounded p-button-text"
              (click)="showAddActiveIssueModal()"
            >
              <span class="p-2">Add Active Issue</span>
            </button>
          </div> -->
        </div>
        <div
          *ngIf="selectedDescription.length > 0"
          class="border-round border-1 border-gray-500"
        >
          <button
            pButton
            pRipple
            class="p-button-rounded p-button-text p-button-sm text-teal-500"
            icon="pi pi-check"
            (click)="showSignOffModal(selectedDescription, selectedDescription)"
          >
            <span class="p-1">Sign off selected</span>
          </button>
          <button
            pButton
            pRipple
            class="p-button-warning p-button-rounded p-button-text p-button-sm text-orange-600 p"
            icon="pi pi-pencil"
            (click)="showEditActiveIssueModal(selectedDescription)"
          >
            <span class="p-1">Update Selected</span>
          </button>
          <button
            pButton
            class="p-button-sm p-button-rounded p-button-text p-button-danger"
            icon="pi pi-times"
            (click)="confirmMultiDelete()"
          >
            <span class="p-1">Delete Selected</span>
          </button>
        </div>

        <p-table
          #dt
          *ngIf="activeIssuesTransformed"
          [value]="activeIssuesTransformed || []"
          [rowHover]="true"
          responsiveLayout="scroll"
          scrollHeight="75vh"
          scrollDirection="both"
          [scrollable]="true"
          [responsive]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          styleClass="p-datatable-sm lg:p-datatable-md"
          [globalFilterFields]="[
            'vehicle_reference',
            'descriptions.description'
          ]"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($event)"
                  placeholder="Search keyword"
                />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th
                class="bg-bluegray-700 border-right-3"
                *ngFor="let col of columns"
                [class.frozen-column]="col.field === 'vehicle_reference'"
                [pSortableColumn]="col.field"
              >
                <span class="font-semibold text-md text-white">{{
                  col.header
                }}</span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-issue>
            <tr (click)="handleCellClick($event)">
              <td class="frozen-column">
                <div class="flex align-items-center gap-3">
                  <span
                    class="mb-2 font-medium text-md text-color-primary align-self-center"
                  >
                    {{ issue.vehicle_reference }}
                  </span>
                  <button
                    class="p-button-sm p-button-rounded bg-bluegray-700"
                    pButton
                    icon="pi pi-file-o"
                    (click)="showNotesDialog(issue)"
                  ></button>
                </div>
              </td>
              <td>
                <div
                  class="flex align-items-center gap-3"
                  (click)="handleCellClick($event)"
                >
                  <div
                    *ngFor="let description of issue.descriptions"
                    style="cursor: pointer"
                  >
                    <div (click)="showSignOffModal(issue, description)">
                      <button
                        pButton
                        pRipple
                        class="hover:border-2 border-gray-900 bg-white-alpha-10 hover:bg-gray-400 border-round-md px-2 py-0 active:bg-white-alpha-10"
                      >
                        <span class="text-gray-900 white-space-nowrap">
                          <p-checkbox
                            [value]="description"
                            [(ngModel)]="selectedDescription"
                            (click)="handleCellClick($event)"
                          ></p-checkbox>
                          {{ description.description }}
                        </span>
                        <button
                          pButton
                          class="p-button-sm p-button-rounded p-button-text p-button-danger py-2"
                          icon="pi pi-times"
                          (click)="
                            confirmDelete(description); handleCellClick($event)
                          "
                        ></button>
                        <button
                          pButton
                          pRipple
                          class="p-button-warning p-button-rounded p-button-text p-button-sm text-orange-600"
                          icon="pi pi-pencil"
                          (click)="
                            showEditActiveIssueModal(description);
                            handleCellClick($event)
                          "
                        ></button>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="2">
                <div class="flex align-items-center justify-content-center">
                  <span class="text-bluegray-800 font-medium text-xl"
                    >No active issues</span
                  >
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="mt-4">
          <h3>Service Reminders</h3>
          <app-service-reminder></app-service-reminder>
        </div>
        <div class="floating-button">
          <button
            *ngIf="selectedDescription.length === 0"
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-info p-button-sm bg-bluegray-700 hover:bg-bluegray-500"
            (click)="showAddActiveIssueModal()"
          >
            <span class="p-2">Add Active Issue</span>
          </button>
          <button
            *ngIf="selectedDescription.length === 0"
            pButton
            pRipple
            type="button"
            icon="pi pi-plus"
            class="p-button-rounded p-button-info p-button-sm bg-bluegray-700 hover:bg-bluegray-500 ml-3"
            (click)="showCreateServiceReminder()"
          >
            <span class="p-2">Add Service Reminder</span>
          </button>
          <button
            *ngIf="selectedDescription.length > 0"
            pButton
            pRipple
            type="button"
            icon="pi pi-minus"
            class="p-button-rounded p-button-info p-button-sm bg-red-500"
            (click)="clearActiveIssueSelection()"
          >
            <span class="p-2">Clear Selection</span>
          </button>
          <button
            *ngIf="selectedDescription.length === 0"
            pButton
            pRipple
            type="button"
            icon="pi pi-file-export"
            class="p-button-rounded p-button-info p-button-sm bg-green-500 ml-3"
            (click)="showReportDialog()"
          >
            <span class="p-2">Generate Reports</span>
          </button>
        </div>
      </p-tabPanel>

      <p-tabPanel>
        <ng-template pTemplate="header">
          <span class="text-bluegray-800 bg">Signed Off Issues</span>
        </ng-template>
        <p-table
          #signedOffIssuesTable
          responsiveLayout="scroll"
          scrollDirection="both"
          [scrollable]="true"
          [responsive]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          [value]="(issuesService.signedOffIssuesObservable | async) || []"
          [rowHover]="true"
          scrollHeight="60vh"
          selectionMode="single"
          [globalFilterFields]="[
            'vehicle_reference',
            'description',
            'fine_amount',
            'signoff_type',
            'date_signed_off'
          ]"
        >
          <ng-template pTemplate="caption">
            <div class="p-d-flex">
              <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="filterGlobal($event)"
                  placeholder="Search keyword"
                />
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th
                class="bg-bluegray-700 border-right-3"
                *ngFor="let col of signedOffColumns"
                [pSortableColumn]="col.field"
              >
                <span class="font-semibold text-md text-white">{{
                  col.header
                }}</span>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product let-columns="columns">
            <tr *ngIf="product.signed_off === true">
              <td *ngFor="let col of signedOffColumns">
                <span>{{ product[col.field] }}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">
                <div class="flex align -items-center justify-content-center">
                  <span class="text-bluegray-800 font-medium text-xl"
                    >No signed off issues</span
                  >
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-tabPanel>
    </p-tabView>
  </div>
  <p-confirmDialog #cd>
    <p-footer>
      <button
        pButton
        type="button"
        label="No"
        class="bg-bluegray-700"
        (click)="cd.reject()"
      ></button>
      <button
        pButton
        type="button"
        label="Yes"
        class="bg-bluegray-700"
        (click)="cd.accept()"
      ></button>
    </p-footer>
  </p-confirmDialog>
</ng-template>

<p-confirmDialog key="archiveIssue"></p-confirmDialog>
