import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HotkeysService } from 'src/app/_services/hotkeys.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-update-tag-dialog',
  templateUrl: './update-tag-dialog.component.html',
  styleUrls: ['./update-tag-dialog.component.scss']
})
export class UpdateTagDialogComponent implements OnInit {
  data: any;
  loading: boolean = false;
  tagForm = this.fb.group({
    hotkey_main: ['', Validators.required],
    hotkey_tag: ['', Validators.required],
    tag_description: ['', Validators.required],
    main_description: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private hotkeysService: HotkeysService,
    public messageService: MessageService,
  ) {
    
   }

  ngOnInit(): void {
    this.data = this.config.data;
    console.log(this.data);

    this.tagForm.patchValue({
      hotkey_main: this.data.hotkey_main,
      hotkey_tag: this.data.hotkey_tag,
      tag_description: this.data.tag_description,
      main_description: this.data.main_description
    });
  }

  updateTag() {
    console.log('tag form', this.tagForm.value);
    if(this.tagForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all fields',
        key: 'hotkeys',
      });
      return;
    }
    this.loading = true;
    this.hotkeysService.updateTag(this.data.id, this.tagForm.value).subscribe(
      (data) => {
        this.hotkeysService.getHotkeys().subscribe((data) => {
          this.hotkeysService.updateHotkeys(data);
        });
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while updating the hotkey',
          key: 'hotkeys',
        });
      },
      () => {
        this.loading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Hotkey updated successfully',
          key: 'hotkeys',
        });
        this.ref.close();
      }
    );

  }
}
