import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ServiceReminders } from '../_models/service-reminders';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceReminderService {
  private serviceReminderSubject = new BehaviorSubject<ServiceReminders[]>([]);
  public serviceReminders$ = this.serviceReminderSubject.asObservable();


  constructor(
    private api: ApiService,
  ) { }

  getReminders(): Observable<ServiceReminders[]> {
    return this.api.getAPI<ServiceReminders[]>('servicereminders').pipe(
      map((response) => response.body || [])
    )
  }

  addReminder(newReminder: any) {
    return this.api.postAPI<ServiceReminders>('servicereminders', newReminder);
  }

  updateReminder(reminder: any, id: number) {
    return this.api.updateAPI<ServiceReminders>(`servicereminders`, id, reminder);
  }

  deleteReminder(id: number) {
    return this.api.deleteAPI<any>('servicereminders', id);
  }

  updateReminders(reminder: ServiceReminders[]) {
    this.serviceReminderSubject.next(reminder);
  }

}
