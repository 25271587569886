import { keyframes } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {
  Observable,
  BehaviorSubject,
  map,
  Subject,
  interval,
  switchMap,
  tap,
  catchError,
  throwError,
} from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IssuesService {
  activeIssues: any;
  private activeIssues$ = new BehaviorSubject<any[]>([]);
  public activeIssuesObservable = this.activeIssues$.asObservable();
  activeIssue: any;
  file_submission_name: any;
  loading = false;
  loadingCsv = false;
  loadingMail = false;
  signedOffIssues: any;
  private signedOffIssues$ = new BehaviorSubject<any[]>([]);
  public signedOffIssuesObservable = this.signedOffIssues$.asObservable();

  constructor(private http: HttpClient, private api: ApiService) {
    this.activeIssues = [];
  }

  getAll() {
    return this.api
      .getAPI<any>('activeissues')
      .pipe(map((response) => response.body));
  }

  // todo: rewrite with current apiUrl having moved to main
  getActiveIssues(): void {
    this.activeIssue = this.api.getAPI<any[]>('activeissues').subscribe(
      (res: any) => {
        console.log('res', res);
        this.activeIssues = res.body;
        this.activeIssues$.next(this.activeIssues);
      },
    );
  }

  getActiveIssue(): Observable<any> {
    return this.api.getAPI<any>('activeissues');
  }

  getSignedOffIssues() {
    return this.api.getAPI<any>('activeissues').subscribe((res: any) => {
      this.signedOffIssues = res.body.filter(
        (issue: any) => issue.signed_off === true
      );
      console.log('signedOffIssues', this.signedOffIssues);
      
      this.signedOffIssues$.next(this.signedOffIssues);
    });
  }

  addActiveIssue(newIssue: any): void {
    this.api.postAPI<any>('activeissues', newIssue).subscribe((res: any) => {
      console.log('res', res);
      this.getActiveIssues();
      this.getSignedOffIssues();
    });
  }

  formatSubmittedIssue(issue: any) {
    if (!issue.description) {
      throw new Error('Description is missing');
    }

    // Extracting only the 'value' from each description object
    let taggedIssues = issue.description.map((element: any) => {
      return element.value;
    });

    let formattedIssue = {
      vehicle_reference: issue.vehicle_reference,
      description: taggedIssues,
      remarks: issue.remarks,
    };
    return formattedIssue;
  }

  getSomething(something: any) {
    this.api.getAPI<any>('activeissues', something).subscribe((res: any) => {});
  }



  updateActiveIssue(issue_id: any, issue: any) {
    return this.api
      .updateAPI<any>('activeissues', issue_id, issue)
      .subscribe((res: any) => {
        console.log('res', res);
        this.getActiveIssues();
        this.getSignedOffIssues();
      });
  }
  // updateActiveIssue(issue_id: any, issue: any): Observable<any> {
  //   return this.api.updateAPI<any>('activeissues', issue_id, issue);
  // }
  deleteActiveIssue(id: number) {
    //  deleteActiveIssue(id: number): Observable<any> {
    return this.api.deleteAPI<any>('activeissues', id).pipe(
      map((res) => {
        console.log('res', res);
        this.getActiveIssues();
        this.getSignedOffIssues();
      }),
    );
  }

  getIssueReport(){
    this.loading = true;
    this.api.getFileAPI<any>('activeissues/generate-report').subscribe((response: any) => {
      let contentDispositionHeader = response.headers.get('Content-Disposition');
      let fileName = '';
  
      if (contentDispositionHeader) {
        let matches = contentDispositionHeader.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        // Corrected the typo here: changed 'legnth' to 'length'
        if (matches && matches.length > 1) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
  
      const blob: Blob = response.body as Blob;
      const a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    },
    (error) => {
      this.loading = false;
    },
    () => {
      this.loading = false
    }

  );
  }

  sendIssueReport(email: any){
    return this.api.postAPI<any>(`activeissues/send-report?email=${email}`, email)
  }

  getCsvReport(){
    this.loadingCsv = true;
    this.api.getFileAPI<any>('activeissues/generate-report-csv').subscribe((response: any) => {
      let contentDispositionHeader = response.headers.get('Content-Disposition');
      let fileName = '';
  
      if (contentDispositionHeader) {
        let matches = contentDispositionHeader.match(
          /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        );
        // Corrected the typo here: changed 'legnth' to 'length'
        if (matches && matches.length > 1) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
  
      const blob: Blob = response.body as Blob;
      const a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();
    }, (error) => {
      this.loadingCsv = false;
    }, () => {
      this.loadingCsv = false;
    }
  
  );
  }

  transformData(issues: any): any[] {
    const mergedIssues: any[] = [];

    issues.forEach((issue: any) => {
      const existingIssue = mergedIssues.find(
        (i) => i.vehicle_reference === issue.vehicle_reference
      );
      if (!issue.signed_off && !issue.fine_amount) {
        if (existingIssue) {
          // Add a new description to the existing row
          existingIssue.descriptions.push({
            id: issue.id,
            description: issue.description,
            vehicle_reference: issue.vehicle_reference,
            remarks: issue.remarks,
          });
        } else {
          // Add a new entry with a new array of descriptions
          mergedIssues.push({
            ...issue,
            descriptions: [
              {
                id: issue.id,
                description: issue.description,
                vehicle_reference: issue.vehicle_reference,
                remarks: issue.remarks,
              },
            ],
          });
        }
      }
    });
    return mergedIssues;
  }
}

//   private updateActiveIssues(res: any): void {
//     this.activeIssues = res.body;
//     this.activeIssues$.next(this.activeIssues);
//   }

//   private handleError(error: any): Observable<never> {
//     console.error('Error updating active issue:', error);
//     return throwError(error);
//   }
// }