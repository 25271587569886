import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HotkeysService } from 'src/app/_services/hotkeys.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-update-hotkey',
  templateUrl: './update-hotkey.component.html',
  styleUrls: ['./update-hotkey.component.scss']
})
export class UpdateHotkeyComponent implements OnInit {
  data: any;
  loading: boolean = false;
  hotkeyForm = this.fb.group({
    hotkey_main: ['', Validators.required],
    main_description: ['', Validators.required],
    hotkey_tag: [''],
    tag_description: [''],
  });

  constructor(
    private fb: FormBuilder,
    private config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private hotkeysService: HotkeysService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.data = this.config.data.hotkey;
    console.log(this.data);
    
    this.hotkeyForm.patchValue({
      hotkey_main: this.data.hotkey_main,
      main_description: this.data.main_description,
    });
  }

  updateHotkey() {
    this.loading = true;

    if(this.hotkeyForm.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all fields',
        key: 'hotkeys',
      });
      this.loading = false;
      return;
    }

    this.hotkeysService.updateHotkey(this.data.id, this.hotkeyForm.value).subscribe(
      (data) => {
        this.hotkeysService.getHotkeys().subscribe((data) => {
          this.hotkeysService.updateHotkeys(data);
        });
      },
      (error) => {
        this.loading = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'An error occurred while updating the hotkey',
          key: 'hotkeys',
        });
      },
      () => {
        this.loading = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Hotkey updated successfully',
          key: 'hotkeys',
        });
        this.ref.close();
      });
  }

}
